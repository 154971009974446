import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import * as Leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-alert',
  templateUrl: './view-alert.page.html',
  styleUrls: ['./view-alert.page.scss'],
})
export class ViewAlertPage implements OnInit {

  map: Leaflet.Map;
  lat: number = 21.9934775;
  lng: number = -99.0176878;
  @Input() data:any = {};
  title:string = "Alerta";
  open_close:boolean = false;
  segmentModel = "info";
  height:string = "350px";
  url:any;

  constructor( private modalCtrl:ModalController, private sanitizer: DomSanitizer,  private platform: Platform ) { 
    platform.ready().then(() => {
      console.log('Width: ' + platform.width());
      console.log('Height: ' + platform.height());
      this.height = `${platform.height()/3.5}px`;
      console.log( this.height );
    });
  }

  ngOnInit() {
    this.leafletMap();
    setTimeout(()=>{
      this.map.invalidateSize();
      this.seguirUnidad();
    }, 500);

    console.log(this.data);

    this.title = this.data.tipo == 2 ? "Ubicación": "Alerta";

  }

  speed( speed:any ){
    if( speed ){   
      let newSpeed = parseFloat( speed ) / 0.62137;  
      return newSpeed.toFixed(1);
    }else{
      return "0";
    }
  }

  cancel(){    
    return this.modalCtrl.dismiss();
    // setTimeout(() => {      
    //   this.modalCtrl.dismiss();
    // }, 500);
  }

  leafletMap(){

    let osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		let osmAttrib = '&copy; OSM - contributors';
		let osm  = Leaflet.tileLayer(osmUrl, { maxZoom: 21, attribution: osmAttrib });
    this.map = Leaflet.map('mapa', { attributionControl: false, center: [this.lat, this.lng], zoom: 16 });

    const here = {
      id: '6AJleReU2wy5FIYdcHUZ',
      code: '2sYGPV-IeanNImtVlcmNpw'
    }
    const style = 'normal.day';

    Leaflet.control.layers({
      'OSM': osm.addTo(this.map),
      "Humanitarian": Leaflet.tileLayer('http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { attribution: 'Map &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> | Tiles &copy; <a href=\"http://hot.openstreetmap.org\">Humanitarian OSM Team</a>', minZoom: 0, maxZoom: 20 }),
      'HERE': Leaflet.tileLayer(`https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}&ppi=320`,{
        attribution:"HERE",
        maxZoom:19
      }),
        'HERE HYBRID': Leaflet.tileLayer(`https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}`,{
        attribution: 'HERE hybrid',
        maxZoom: 19
      }),
      'Google Streets': Leaflet.tileLayer('http://www.google.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}', {
	      attribution: 'google',
	      maxZoom: 20,
      }),           
      "Google Traffic": Leaflet.tileLayer('https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20,
          minZoom: 2,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      "Google Hybrid": Leaflet.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20
      })  
    }).addTo(this.map);

    console.log(this.data);
    this.addMarca();

  }

  addMarca(){

    let url = this.data.tipo == 1 ? '../../../assets/icons/caution.png': '../../../assets/images/marca-blue.png';

    let iconPDI = Leaflet.icon({
      iconUrl: url,
      iconSize: [32, 37],
      iconAnchor: [16, 19],
      popupAnchor: [-1, -25]
    });
    const marcaEvento = Leaflet.marker([this.data.lat, this.data.lng], {icon:iconPDI}).addTo(this.map);
    // marcaEvento.bindPopup(`<strong>Objeto:</strong>${this.data.nom_user}<br><strong>Tipo de evento:</strong>${this.tipoEvento(this.data.clave_msj)}<br><strong>Dirección:</strong>${this.data.direccion}<br><strong>Coordenadas:</strong><a href='http://www.google.com.mx/maps?q=${this.data.lat},${this.data.lng}' target='_blank'>${this.data.lat},${this.data.lng}</a><br><strong>Nom. Evento:</strong>${this.data.nom_evento}<br><strong>Motor:</strong>${this.motor(this.data.acc)}<br><strong>Velocidad:</strong>${this.speedNull(this.data.speed)}km/h<br><strong>Fecha:</strong>${this.getTIMESTAMP(this.data.timestamp )}`).openPopup();
    this.map.setView([this.data.lat, this.data.lng], 18);
  }

  getTIMESTAMP(timestamp:any) {
    let months:any = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    let date:any = new Date(timestamp);
    let year:any = date.getFullYear();
    let month:any = ("0"+(date.getMonth()+1)).substr(-2);
    let day = ("0"+date.getDate()).substr(-2);
    let hour = ("0"+date.getHours()).substr(-2);
    let minutes = ("0"+date.getMinutes()).substr(-2);
    let seconds = ("0"+date.getSeconds()).substr(-2);

    return months[month-1]+" "+day+", "+year+" "+hour+":"+minutes+":"+seconds;
  }

  speedNull( speed:any ){
    if( speed ){      
      let newSpeed = parseInt(speed) / 0.62137;        
      return newSpeed.toFixed(1);      
    }else{
      return 0;
    }
  }

  motor( status:any ){

    if( status == "0" ){
      return "Apagado";
    }else if( status == "1" ){
      return "Encendido";
    }else{
      return "!";
    }
  }

  tipoEvento( tipo:any ){

    if( tipo == 'IN_GEO' ){
      return 'ENTRADA GEOCERCA';
    }

    if( tipo == 'OUT_GEO' ){
      return 'SALIDA GEOCERCA';
    }

    if(tipo == 'ACC_OFF'){
      return 'MOTOR APAGADO';
    }

    if( tipo == 'ACC_ON' ){
      return 'MOTOR ENCENDIDO';
    }

    if( tipo == 'SPEEDING' ){
      return 'EXCESO DE VEL.';
    }

    if( tipo == 'PANIC' ){
      return 'BOTON DE PANICO';
    }

    if( tipo == 'IN_RUTA' ){
      return 'ENTRADA A RUTA';
    }

    if( tipo == 'OUT_RUTA' ){
      return 'SALIDA DE RUTA';
    }

    if( tipo == 'LOW_BATTERY' ){
      return 'BATERIA BAJA';
    }

  }

  closeOpen(){
    this.open_close = this.open_close ? false : true;
    setTimeout(()=>{
      this.map.invalidateSize();
    }, 500);
  }

  segmentChanged( event:any ){
    console.log(this.segmentModel);
    this.segmentModel = event.detail.value;
    console.log(event);
  }

  seguirUnidad(){
    setTimeout(() => {
      console.log(`http://trackingservice.com.mx/street-view.php?lat=${this.data.lat}&lng=${this.data.lng}&direccion=${this.data.direccion}&speed=${this.data.speed}&ignicion=0&fecha=0&ubic=F-350%20SLP&eco=F-350%20SL`);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(      
        `http://trackingservice.com.mx/street-view.php?lat=${this.data.lat}&lng=${this.data.lng}&direccion=${this.data.direccion}&speed=${this.data.speed}&ignicion=0&fecha=0&ubic=F-350%20SLP&eco=F-350%20SL`
      );

    }, 500);
  }

}
